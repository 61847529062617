import Firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID,
  measurementId: process.env.VUE_APP_MEASUREMENTID
}

if (!Firebase.apps.length) {
  Firebase.initializeApp(firebaseConfig)
}

const auth = Firebase.auth()
const fs = Firebase.firestore()

if (process.env.NODE_ENV === 'development') {
  console.log('Local Auth and Firestore Emulators running...')
  fs.settings({
    experimentalForceLongPolling: true
  })
  fs.useEmulator('localhost', 8082)
  auth.useEmulator('http://localhost:9099')
}

Firebase.analytics()

export const FirebaseAuth = auth
export const Firestore = fs
export default Firebase
