



































































































































import Vue from 'vue'
import { FirebaseAuth, Firestore } from '../src/firebase'
import { mapGetters } from 'vuex'
import { VideoCallStatus } from './views/enums/VideoCallStatus'

export default Vue.extend({
  name: 'App',

  data: () => ({
    drawer: false,
    group: null
  }),
  computed: {
    ...mapGetters({
      user: 'user',
      family: 'family'
    }),
    HasVideoFeature () {
      return this.family.data.videoFeature
    },
    IsCallRunning () {
      let videoCallRunningStatus = false
      if (this.family.data.id) {
        // is there a call running for the current user?
        videoCallRunningStatus = this.family.localVideoCallRunning
        console.log('videoCallRunning Status: ' + videoCallRunningStatus)
      }
      return videoCallRunningStatus
    }
  },
  methods: {
    logOut () {
      FirebaseAuth.signOut().then(() => {
        this.$router.push('/')
      })
    },
    startVideoCall () {
      this.$router.push('/videocall')
    },
    CancelCall () {
      // Change document state to "ENDED" for current videocall doc
      if (this.family.data.id) {
        this.$store.commit('SET_LOCALVIDEOCALL_RUNNING', false) // Set local state
        const videoCallCollection = Firestore.collection('videocalls')
        videoCallCollection
          .where('familyId', '==', this.family.data.id)
          .where('callerId', '==', this.user.data.uid)
          .where('status', 'in', [VideoCallStatus.Calling, VideoCallStatus.Accepted])
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(doc => {
              doc.ref.update({ status: VideoCallStatus.Hungup, endTime: new Date() })
            })
            const jitsi = this.family.jitsi
            if (jitsi !== null) {
              jitsi.dispose()
              console.log('Videocall disposed')
            }
            // go to callended view
            this.$router.push('/callended')
          })
      }
    }
  }
})
